// client/src/components/ui/input.js
import React from 'react';

export const Input = ({ type = 'text', ...props }) => (
  <input
    type={type}
    style={{
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      backgroundColor: '#333',
      color: '#fff',
    }}
    {...props}
  />
);