import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import TradingPage from './components/TradingPage';
import PortfolioPage from './components/PortfolioPage';
import SpekuleGpt from './components/SpekuleGpt';
import CoingeckoPage from './components/CoingeckoPage';
import UltraPage from './components/UltraPage';
import ScreamerPage from './components/ScreamerPage';
import './styles/main.css';
import './styles/main.css';
import RegisterPage from './components/RegisterPage';
import { LandingPage }from './components/LandingPageComponents/LandingPage';
import { EmailVerification } from './components/auth/EmailVerification';
function App() {
  const [userPreference, setUserPreference] = useState('');

  // Cookie management functions
  const setCookie = async (preference) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/set-cookie`, {
        preference
      });
      console.log('Cookie set:', response.data);
    } catch (error) {
      console.error('Error setting cookie:', error);
    }
  };

  const getCookie = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-cookie`);
      setUserPreference(response.data.preference);
    } catch (error) {
      console.error('Error getting cookie:', error);
    }
  };

  // Load user preferences when app starts
  useEffect(() => {
    getCookie();
  }, []);
  return (
    <UserProvider>
      <Router>
          <main className="app-main">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/verify-email" element={<EmailVerification />} />
              <Route path="/trading/dashboard" element={<Dashboard />} />
              <Route path="/trading" element={<TradingPage />} />
              <Route path="/portfolio" element={<PortfolioPage />} />
              <Route path="/spekule-gpt" element={<SpekuleGpt />} />
              <Route path="/coingecko" element={<CoingeckoPage />} />
              <Route path="/ultra" element={<UltraPage />} />
              <Route path="/screamer" element={<ScreamerPage />} />
              <Route path="/" element={<Home />} />
            </Routes>
            {/* Optional: Display user preference somewhere in the app */}
          {userPreference && (
            <div className="user-preference">
              Current Preference: {userPreference}
            </div>
          )}
          </main>
      </Router>
    </UserProvider>
  );
}



function Home() {
  return <LandingPage />;
}

export default App;






