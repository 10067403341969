import { BarChart2, Zap, Lock } from 'lucide-react'
import { FeatureCard } from './featureCard'

const features = [
  {
    name: 'Advanced Analytics',
    description: 'Get deep insights into market trends with our powerful analytical tools.',
    icon: BarChart2,
  },
  {
    name: 'Lightning Fast Trades',
    description: 'Execute trades at lightning speed with our optimized trading engine.',
    icon: Zap,
  },
  {
    name: 'Bank-Grade Security',
    description: 'Rest easy knowing your assets are protected by top-tier security measures.',
    icon: Lock,
  },
]

export function FeaturesSection() {
  return (
    <section id="features" className="py-24 sm:py-32 relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base text-center font-semibold leading-7 text-purple-400">Trade Smarter</h2>
          <p className="mt-2 text-3xl text-center font-bold tracking-tight text-white sm:text-4xl">
            Everything you need to master crypto trading
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-3">
            {features.map((feature) => (
              <FeatureCard key={feature.name} {...feature} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

