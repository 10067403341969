import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NotificationPanel } from './tradingComponents/NotificationPanel';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
export function TopNav() {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };
  // Determine the page title based on the current path
  const getPageTitle = () => {
    switch (location.pathname) {
      case '/trading':
        return 'TRADING';
      case '/portfolio':
        return 'PORTFOLIO';
      case '/spekule-GPT':
        return 'SPEKULEGPT';
      case '/trading/dashboard':
        return 'DASHBOARD';
      case '/coingecko':
        return 'TOKENS FIB LEVELS';
      case '/ultra':
        return 'ULTRATRADER';
      case '/screamer':
        return 'SCREAMER WATCHLIST';
      // Add more cases as needed
      default:
        return 'SPEKULE TERMINAL';
    }
  };

  return (
    <header className="flex h-16 items-center justify-between border-b px-4">
      <div className="text-lg font-bold animate-pulse">{getPageTitle()}</div>
      <div className="flex items-center gap-6 ml-auto">
      <button onClick={togglePanel}>
          <NotificationsIcon />
        </button>
        {isPanelOpen && <NotificationPanel notifications={notifications} />}
        <img 
          src="/logoSpekuleTransparent.png" 
          alt="Spekule Logo" 
          className="w-8 h-8 hover:w-12 hover:h-12 transition-transform duration-300"
        />
      </div>    
    </header>
  );
}