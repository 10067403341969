import React, { useEffect, useState } from 'react';
import { fetchNews } from '../../services/api';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card"
import { ScrollArea } from "../ui/scroll-area"

export function CryptoNews() {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsOnce = async () => {
            const newsData = await fetchNews();
            setNews(newsData);
            setLoading(false);
        };

        fetchNewsOnce();
    }, []);

    
        return (
          <Card className="h-90 bg-transparent">
            <CardHeader>
              <CardTitle>Hot News</CardTitle>
            </CardHeader>
            <CardContent className="h-[calc(100%-2rem)]">
              <ScrollArea className="h-full">
                <ul className="space-y-4">
                  {news.map((item, index) => (
                    <li key={index} className="border-b border-transparent pb-4 last:border-b-0 last:pb-0">
                      <h3 className="font-semibold">
                        <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                          {item.title}
                        </a>
                      </h3>
                      <div className="text-sm text-muted-foreground mt-1">
                        <span>{new Date(item.published_at).toLocaleString()}</span> • <span>{item.source.title}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </ScrollArea>
            </CardContent>
          </Card>
        );
}

