import { useState, useEffect } from 'react';
import { setUserPreferenceCookie, getUserPreferenceCookie } from '../services/api';

export function useCookies() {
  const [preference, setPreference] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updatePreference = async (newPreference) => {
    try {
      await setUserPreferenceCookie(newPreference);
      setPreference(newPreference);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    const loadPreference = async () => {
      try {
        const data = await getUserPreferenceCookie();
        setPreference(data.preference);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    loadPreference();
  }, []);

  return {
    preference,
    updatePreference,
    loading,
    error
  };
}