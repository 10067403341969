// client/src/services/api.js
import axios from 'axios';
const BackendUrl = process.env.REACT_APP_API_BASE_URL
export async function login(username, password) {
    try {
        const response = await fetch(`${BackendUrl}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error during login:', error);
        return { success: false, message: error.message };
    }
}
// client/src/services/api.js

export async function deleteNotification(id) {
    try {
        const response = await fetch(`${BackendUrl}/api/delete-notification/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Failed to delete notification');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deleting notification:', error);
        throw error;
    }
}

export async function fetchNotifications(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-notifications?username=${username}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function setUserPreferenceCookie(preference) {
    try {
      const response = await fetch(`${BackendUrl}/api/set-cookie`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ preference }),
        credentials: 'include', // Important for cookies
      });
      return await response.json();
    } catch (error) {
      console.error('Error setting cookie:', error);
      throw error;
    }
  }
  
  export async function getUserPreferenceCookie() {
    try {
      const response = await fetch(`${BackendUrl}/api/get-cookie`, {
        credentials: 'include', // Important for cookies
      });
      return await response.json();
    } catch (error) {
      console.error('Error getting cookie:', error);
      throw error;
    }
  }

export async function register(username, password, email) {
    try {
        const response = await fetch(`${BackendUrl}/api/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password, email }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error during registration:', error);
        return { success: false, message: error.message };
    }
}
export const sendVerificationCode = async (email , username) => {
    try {
        const response = await axios.post(`${BackendUrl}/api/send-verification-code`, { email , username});
        return response.data;
    } catch (error) {
        console.error('Error sending verification code:', error);
        return { success: false, message: 'Failed to send verification code' };
    }
};
export async function verifyCode(email, code) {
    try {
        const response = await axios.post(`${BackendUrl}/api/verify-code`, { email, code });
        return response.data;
    } catch (error) {
        console.error('Error verifying code:', error);
        return { success: false, message: 'Failed to verify code' };
    }
}

function getLogoPath(exchange) {
    const logoMap = {
      binance: '/exchangeLogos/BinanceLogo.svg',
      kucoin: '/exchangeLogos/KuCoin.svg',
      bitget: '/exchangeLogos/BitGetLogosvg.svg',
      bybit: '/exchangeLogos/Bybit.svg',
    };
  
    return logoMap[exchange] || null; // Return null or a default image path if the logo is not found
  }

export async function fetchAccountData(username) {
    try {
        console.log("Fetching user assets for username :", username);
        const response = await fetch(`${BackendUrl}/api/account-data/${username}`);
        const data = await response.json();

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Add logo URL to each asset
        for (const asset of data) {
            asset.logo = getLogoPath(asset.logo);
        }

        return data;
    } catch (error) {
        console.error('Error fetching account data:', error);
        return null;
    }
}

export async function addExchange(exchangeData) {
    try {
        const response = await fetch(`${BackendUrl}/api/add-exchange`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(exchangeData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error adding exchange:', error);
        return { success: false, message: error.message };
    }
}

export async function executeSwap(swapData) {
    try {
        const response = await fetch(`${BackendUrl}/api/swap`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(swapData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error executing swap:', error.message);
        throw new Error(error.message || 'An error occurred');
    }
}
export async function getActiveTrades(username) {
    const response = await fetch(`${BackendUrl}/api/trades/active-trades/${username}`);
    if (!response.ok) {
        throw new Error('Failed to fetch active trades');
    }
    const data = await response.json();
    return data;
}
export async function fetchTradeHistory(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/trades/history/${username}`);
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error('Failed to fetch trade history');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching trade history:', error.message);
        throw error;
    }
}

export async function fetchLimitOrders(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/trades/limit-orders/${username}`);
        if (!response.ok) {
            throw new Error('Failed to fetch limit orders');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching limit orders:', error.message);
        throw error;
    }
}

export async function cancelLimitOrder(orderData) {
    try {
        const response = await fetch(`${BackendUrl}/api/trades/cancel-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Failed to cancel order');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error canceling order:', error.message);
        throw error;
    }
}

export async function AutomationSwitch(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/user/automation`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        });

        // Check if the response is okay
        if (!response.ok) {
            throw new Error('Failed to update automation status');
        }

        // Parse the response as JSON
        const data = await response.json();
        return data; 
    } catch (error) {
        console.error('Error updating automation status:', error);
        throw error;
    }
}
export async function fetchUltraData15M(requestData) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-ultra15M`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Failed to fetch ultra data');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error loading ultra data:', error.message);
        throw error;
    }
}

export async function fetchUltraData1D(requestData) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-ultra1D`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(errorText || 'Failed to fetch ultra data');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error loading ultra data:', error.message);
        throw error;
    }
}


export async function fetchGoogleSheetsTokens() {
    try {
        const response = await fetch(`${BackendUrl}/api/google-sheets-tokens`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching Google Sheets tokens:', error);
        throw error;
    }
}
export async function fetchTokensByMarketCap() {
    try {
        const response = await fetch(`${BackendUrl}/api/fetch-tokens-by-market-cap`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching tokens by market cap:', error);
        throw error;
    }
}



export async function fetchDailyProfitLoss(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/fetching-daily-profit-loss/${username}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching daily profit and loss:', error);
        throw error;
    }
}

export async function fetchSystemMetrics(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/system-metrics/${username}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching system metrics:', error);
        throw error;
    }
}

export async function fetchAllMetrics(username) {
    try {
        const response = await fetch(`${BackendUrl}/api/all-metrics/${username}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching all metrics:', error);
        throw error;
    }
}

export async function AskGptPromt(userInput, username){
    try {
        const response = await fetch(`${BackendUrl}/api/spekule-gpt`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userInput , username }),
        });

        
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error communicating with the server:', error);
      }
}
export async function fetchOrderBookforHistogram(symbol) {
    try {
            const response = await axios.get(`${BackendUrl}/api/orderbookForHistogram`, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            params: { symbol },
          });
        
          const data = response.data;
          return data;
      } catch (error) {
        console.error('Error fetching order book data:', error);
        return [];
      }
}

export async function fetchCurrentPrice(symbol){
    try {
        const response = await axios.get(`${BackendUrl}/api/current-price`, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            params: { symbol },
          });
          const data = response.data;
          return data;
      } catch (error) {
        console.error('Error fetching current price:', error);
        return [];
      }
}

export async function fetchNews() {
    try {
        const response = await axios.get(`${BackendUrl}/api/news`);
        return response.data;
    } catch (error) {
        console.error('Error fetching news:', error);
        return [];
    }
}