import React from 'react';
import { CoinGeckoComponent } from './tradingComponents/CoinGeckocomponent';
import { Sidebar } from './Sidebar';
import { TopNav } from './top-nav';
function CoingeckoPage() {
    return (
      <div className="flex h-screen bg-background">
        <Sidebar />
        <div className="flex flex-col flex-1 overflow-hidden">
          <TopNav />
          <main className="flex-1 overflow-y-auto">
            <div className="w-full">
              <CoinGeckoComponent />
            </div>
          </main>
        </div>
      </div>
    );
}

export default CoingeckoPage;