import { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { fetchDailyProfitLoss } from '../../services/api';
import { Card, CardHeader, CardContent } from '../ui/card';
export function DailyProfitLossComponent() {
    const { user } = useUser();
    const username = user.username;
    const [dailyProfitLoss, setDailyProfitLoss] = useState([]);

    useEffect(() => {
        const fetchProfitLoss = async () => {
            try {
                const dailyProfitLoss = await fetchDailyProfitLoss(username);
                setDailyProfitLoss(dailyProfitLoss || []);
            } catch (error) {
                console.error('Error fetching daily profit loss:', error);
            }
        };
        fetchProfitLoss();
    }, [username]);
    const totalProfitLoss = dailyProfitLoss.reduce((acc, { percentage }) => acc + percentage, 0);
    const percentage = totalProfitLoss / dailyProfitLoss.length || 0;


 return (
        <Card>
            <CardHeader title="Daily Profit/Loss" />
            <CardContent>
                <div className="text-3xl font-bold">
                    ${totalProfitLoss.toLocaleString()}
                </div>
                <div
                    className={`text-lg ${
                        percentage >= 0 ? "text-green-500" : "text-red-500"
                    }`}
                >
                    {percentage >= 0 ? "+" : "-"}
                    {Math.abs(percentage).toFixed(2)}%
                </div>
            </CardContent>
        </Card>
    );



}