import React, { useState } from 'react';
import {TradingViewChart} from './TradingViewChart'; // Import your TradingView chart component
import {FundingRateComponent} from './FundingRatecomponent'; // Import your Funding Rate component
import {OrderBookHistogramComponent} from './OrderbookHistogram'; // Import your Histogram component
import { Input } from '../ui/input';
import { Button } from '../ui/Button';

export function ChartComponent() {
    const [symbol, setSymbol] = useState('BTCUSDT');
    const [searchSymbol, setSearchSymbol] = useState('BTCUSDT');
    const [selectedChart, setSelectedChart] = useState('tradingview')

    const handleClick = () => {
        setSearchSymbol(symbol)
    }
    
    return (
      <div className="w-full" style={{ height: '800px' }}>
        <div className="space-y-6">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              onClick={() => setSelectedChart('tradingview')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedChart === 'tradingview' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
              }`}
            >
              TradingView
            </button>
            <button
              onClick={() => setSelectedChart('fundingrate')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedChart === 'fundingrate' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
              }`}
            >
              Funding Rate
            </button>
            <button
              onClick={() => setSelectedChart('orderbook')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedChart === 'orderbook' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
              }`}
            >
              Orderbook
            </button>
          </div>

          <div className="flex space-x-2 items-center">
            <Input
              placeholder="Enter symbol (e.g., BTCUSDT)"
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
              className="flex-grow h-8"
            />
            <Button onClick={handleClick} disabled={!symbol} className="bg-purple-600 hover:bg-purple-700 text-white px-2 py-1 rounded-lg">
              Fetch
            </Button>
          </div>
    
          {symbol && (
            <div className="mt-6">
              {selectedChart === 'tradingview' && <TradingViewChart symbol={searchSymbol} />}
              {selectedChart === 'fundingrate' && <FundingRateComponent symbol={searchSymbol} />}
              {selectedChart === 'orderbook' && <OrderBookHistogramComponent symbol={searchSymbol} />}
            </div>
          )}
        </div>
      </div>
    );


}
