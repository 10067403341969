import { useState, useEffect } from 'react';
import Plotly from 'plotly.js-dist';


export function FundingRateComponent(props) {
  const {symbol: symbolProps} = props;

    const [symbol, setSymbol] = useState("BTCUSDT"); // Default coin name set to BTC
    const [error, setError] = useState(null);
   
    useEffect(() => {
      setSymbol(symbolProps);
    }, [symbolProps]);
  
   
    useEffect(() => {
      handleFetchData(); // Call handleFetchData on component mount to fetch data for BTC
    }, [symbol]);
  
    const handleFetchData = async () => {
      let apiEndPoint1 = `https://fapi.binance.com/fapi/v1/premiumIndex?symbol=${symbol}`;
      let apiEndPoint2 = `https://fapi.binance.com/fapi/v1/fundingRate?symbol=${symbol}&limit=42`;
  
      try {
        const premiumIndexResponse = await fetch(apiEndPoint1);
        const premiumIndexData = await premiumIndexResponse.json();
  
        const fundingRateResponse = await fetch(apiEndPoint2);
        const fundingRateData = await fundingRateResponse.json();
  
        const x = fundingRateData.map(item => new Date(item.fundingTime).toISOString());
        const y = fundingRateData.map(item => item.fundingRate * 100); // Convert to percentage
  
        const trace = {
          x: x,
          y: y,
          type: 'scatter',
          mode: 'lines+markers',
          name: 'Funding Rate',
        };
  
        const layout = {
          title: 'Funding Rate over Time (14 Days)',
          xaxis: {
            title: 'Time',
            type: 'date',
          },
          yaxis: {
            title: 'Funding Rate (%)',
            tickformat: '.2f', // Format as float
          },
        };
  
        Plotly.newPlot('fundingVStime', [trace], layout);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Error fetching data: ${error.message}`);
      }
    };
 
    return (
      <div id="fundingVStime" style={{ width: '100%', height: 'auto' }}></div>
    );
 }