
import { UltraComponent } from './tradingComponents/UltraComponent';
import { Box } from '@mui/material';
import { Sidebar } from './Sidebar';
import { TopNav } from './top-nav';

export default function UltraPage() {
    
    return (  
        <div className="flex h-screen bg-background">
        <Sidebar />
        <div className="flex flex-col flex-1 overflow-hidden">
          <TopNav />
          <main className="flex-1 overflow-y-auto p-4">
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-center mb-4 font-bold text-white glow">Ultra Heat-map</h2>
              <div className="flex justify-center">
                <UltraComponent />
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  
}