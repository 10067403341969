import { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { cancelLimitOrder, fetchLimitOrders } from '../../services/api';
import { toast } from 'react-toastify';
import { Table} from '@mui/material';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/Button";
import { TableHead, TableRow, TableCell, TableBody, TableHeader } from "../ui/table-header";
export function LimitOrdersComponent() {
    const { user } = useUser();
    const [limitOrders, setLimitOrders] = useState([]);
    const tokensCache = useState(null); // Define tokensCache
  
  
    useEffect(() => {
      async function fetchOrders() {
        if (tokensCache.current) {
          setLimitOrders(tokensCache.current);
          return;
        }
        try {
          const orders = await fetchLimitOrders(user.username);
          tokensCache.current = orders; // Cache the data
          setLimitOrders(orders);
        } catch (error) {
          console.error('Error fetching limit orders:', error);
        }
      }
      fetchOrders();
    }, [user.username]);
  
    const handleCancelOrder = async (tradeId, exchange, symbol) => {
      try {
        const orderData = {
          tradeId,
          username: user.username,
          exchange,
          symbol,
        };
        const result = await cancelLimitOrder(orderData);
        if (result.success) {
          toast.success('Order canceled successfully!');
          setLimitOrders(limitOrders.filter(order => order.tradeId !== tradeId));
          return true;
        } else {
          toast.error(result.message || 'Failed to cancel order');
          return false;
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
        return false;
      }
    };
  



    return (
      <Card className="bg-[#0a0b0f] text-white h-96 overflow-hidden">
        <CardContent className="h-full overflow-y-auto">
          <Table>
            <TableHeader>
              <TableRow className="border-gray-800">
                <TableHead>Trade ID</TableHead>
                <TableHead>Symbol</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Side</TableHead>
                <TableHead>Entry Price</TableHead>
                <TableHead>Exchange</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {limitOrders.map((order) => (
                <TableRow key={order.tradeId} className="border-gray-800">
                  <TableCell className="font-medium">{order.tradeId}</TableCell>
                  <TableCell>{order.symbol}</TableCell>
                  <TableCell>{order.amount}</TableCell>
                  <TableCell className={order.side === "buy" ? "text-green-500" : "text-red-500"}>
                    {order.side}
                  </TableCell>
                  <TableCell>{order.entryPrice}</TableCell>
                  <TableCell>{order.exchange}</TableCell>
                  <TableCell className="text-xs">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleCancelOrder(order.tradeId, order.exchange, order.symbol)}
                        sx={{
                          backgroundColor: '#ff1744',
                          color: '#000000',
                          '&:hover': { backgroundColor: '#d50000' },
                        }}
                      >
                        Cancel
                      </Button>
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )

  }