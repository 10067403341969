import { useEffect, useState } from 'react';
import { fetchGoogleSheetsTokens } from '../../services/api';
import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

export function GoogleSheetsComponent() {
    const [tokens, setTokens] = useState([]);
    useEffect(() => {
        fetchGoogleSheetsTokens().then(setTokens);
    }, []);

    const renderTableCell = (cellData, cellIndex) => {
        const isNumeric = !isNaN(cellData);
        const backgroundColor = cellIndex === 0 ? '#303F9F' : 'inherit';
        const color = cellIndex === 0 ? '#FFFFFF' : isNumeric ? 
            parseFloat(cellData) <= 0.3 ? '#FFFFFF' : 
            parseFloat(cellData) <= 0.6 ? '#FFFF00' : '#00FFFF' : '#FFFFFF';
        return (
            <TableCell key={cellIndex} sx={{ backgroundColor, color }}>
                {cellData}
            </TableCell>
        );
    };



    return (
        <Paper sx={{ padding: 1, backgroundColor: '#1e1e1e' , maxWidth: 1000, margin: 'auto' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ color: '#00bcd4', fontWeight: 'bold' }}>TICKER</TableCell>
                        <TableCell sx={{ color: '#00bcd4', fontWeight: 'bold' }}>X/USD</TableCell>
                        <TableCell sx={{ color: '#00bcd4', fontWeight: 'bold' }}>X/BTC3L</TableCell>
                        <TableCell sx={{ color: '#00bcd4', fontWeight: 'bold' }}>Change/USD</TableCell>
                        <TableCell sx={{ color: '#00bcd4', fontWeight: 'bold' }}>Change/BTC3L</TableCell>
                        <TableCell sx={{ color: '#00bcd4', fontWeight: 'bold' }}>BTFD/USD</TableCell>
                        <TableCell sx={{ color: '#00bcd4', fontWeight: 'bold' }}>BTFD/BTC3L</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(tokens) && tokens.length > 0 ? (
                        tokens.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {Array.isArray(row) && row.length > 0 ? (
                                    row.map((cellData, cellIndex) => renderTableCell(cellData, cellIndex))
                                ) : (
                                    <TableCell colSpan={7}>No data available</TableCell>
                                )}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7}>No data available</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
}

