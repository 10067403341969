import { useState } from 'react';
import { useUser } from '../../context/UserContext';
import { AutomationSwitch } from '../../services/api';
import { Switch } from '@mui/material';
export function AutomatedSettingsComponent() {
    const { user } = useUser();
    const [automationEnabled, setAutomationEnabled] = useState(false);
  
    const handleToggle = async () => {
      try {
        const newStatus = !automationEnabled;
        setAutomationEnabled(newStatus);
        await AutomationSwitch(user.username);
      } catch (error) {
        console.error('Error updating automation status:', error);
      }
    };
    return (
      <div>
        <Switch
          checked={automationEnabled}
          onChange={handleToggle}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    )
  }