import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register , sendVerificationCode} from '../../services/api'; // Assume you have a register function in your API service
// import './LoginRegisterForm.css';
import './LoginForm.css';
import { Card, CardHeader, CardContent, CardFooter } from '../ui/card';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { Mail } from 'lucide-react';
import { Label } from '../ui/label';
import { Button } from '../ui/Button';
import { User, Lock, Eye, EyeOff } from 'lucide-react';
import {Link} from 'react-router-dom';
export function RegisterFormComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
          alert('Passwords do not match');
          return;
      }
        const data = await sendVerificationCode(email , username);
        if (data.success) {
            setIsVerificationSent(true);
            navigate('/verify-email', { state: { email, username, password } });
        } else {
            alert(data.message || 'Failed to send verification code');
        }
    };
    // return (
    //     <div className='register-form'>
    //         <h2>Register</h2>
    //         <form onSubmit={handleSubmit}>
    //             <input
    //                 type="text"
    //                 placeholder="Username"
    //                 value={username}
    //                 onChange={(e) => setUsername(e.target.value)}
    //             />
    //             <input
    //                 type="email"
    //                 placeholder="Email"
    //                 value={email}
    //                 onChange={(e) => setEmail(e.target.value)}
    //             />
    //             <input
    //                 type="password"
    //                 placeholder="Password"
    //                 value={password}
    //                 onChange={(e) => setPassword(e.target.value)}
    //             />
    //             <button type="submit">Register</button>
    //         </form>
    //     </div>
    // );

    const togglePasswordVisibility = () => setShowPassword(!showPassword)
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword)

  return (
    <Card className="w-full max-w-md bg-card/50 backdrop-blur-md border-border">
      <CardHeader className="space-y-1 flex flex-col items-center">
        <div className="relative w-32 h-32 mb-4">
          <img
            src="/logoSpekuleTransparent.png"
            alt="Crypto Trading Terminal Logo"
            className="object-contain w-full h-full"
          />
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-foreground">Create an account</h1>
        <p className="text-muted-foreground">Enter your details to register</p>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="space-y-4">
          <div>
            <Label htmlFor="username" className="text-gray-200">Username</Label>
            <Input
              id="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <User className="text-white" size={18} />
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="email" className="text-gray-200">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Mail className="text-white" size={18} />
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="password" className="text-gray-200">Password</Label>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Lock className="text-white" size={18} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <EyeOff className="text-gray-400" size={18} />
                    ) : (
                      <Eye className="text-gray-400" size={18} />
                    )}
                  </Button>
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="confirmPassword" className="text-gray-200">Confirm Password</Label>
            <Input
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Lock className="text-white" size={18} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <EyeOff className="text-muted-foreground" size={18} />
                    ) : (
                      <Eye className="text-muted-foreground" size={18} />
                    )}
                  </Button>
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button type="submit" className="w-full bg-primary text-primary-foreground hover:bg-primary/90">
            Create Account
          </Button>
          <div className="text-sm text-muted-foreground text-center">
            Already have an account?{' '}
            <Link to="/login" className="text-primary hover:underline">
              Sign in
            </Link>
          </div>
        </CardFooter>
      </form>
    </Card>
  );
}

// export default RegisterForm;
