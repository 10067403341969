import React, { useState } from 'react';
import { Button } from '@mui/material'; // or wherever your Button component is from
import { ChevronRight } from '@mui/icons-material'; // or wherever your icon is from
import cn from 'classnames'; // Ensure you have this package installed
import ScrollArea from 'react-scrollbar'; // or 
import { Link } from 'react-router-dom';
import { Dashboard as DashboardIcon, Add as AddIcon, AccountBalanceWallet as WalletIcon, TrendingUp as TrendingUpIcon, SmartToy as AIIcon, BarChart as CoingeckoIcon, GridOn as UltraIcon, TableChart as SheetsIcon, ToggleOn as ToggleOnIcon } from '@mui/icons-material';
import { AutomatedSettingsComponent } from './tradingComponents/AutomationSwitchComponent';
import { useUser } from '../context/UserContext';
import { addExchange } from '../services/api';
import './Sidebar.css';



export function Sidebar(props) {
  const { user } = useUser();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [exchangeData, setExchangeData] = useState({
    exchangeName: '',
    apiKey: '',
    apiSecret: ''
  });  
  const [open, setOpen] = useState(false);

  const handleAddExchange = async () => {
    if (!user || !user.username) {
      alert('User not logged in');
      return;
    }
  
    const payload = {
      username: user.username, // Ensure username is included
      exchange: exchangeData.exchangeName, // Map exchangeName to exchange
      apiKey: exchangeData.apiKey,
      apiSecret: exchangeData.apiSecret,
      passphrase: exchangeData.passphrase || '' // Include passphrase if needed
    };
  
    console.log('Payload:', payload);   
    const result = await addExchange(payload);
    if (result.success) {
      alert('Exchange added successfully');
      setOpen(false);
    } else {
      alert(result.message || 'Failed to add exchange');
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExchangeData((prevData) => ({ ...prevData, [name]: value }));
  };


  const navItems = [
    { name: "Dashboard", icon: DashboardIcon, href: "/trading/dashboard", link: true },
    { name: "Portfolio", icon: WalletIcon, href: "/portfolio", link: true },
    { name: "Trading", icon: TrendingUpIcon, href: "/trading", link: true },
    { name: "Spekule AI", icon: AIIcon, href: "/spekule-GPT", link: true },
    { name: "Coingecko", icon: CoingeckoIcon, href: "/coingecko", link: true },
    { name: "Ultra", icon: UltraIcon, href: "/ultra", link: true },
    { name: "Screamer", icon: SheetsIcon, href: "/screamer", link: true },
  ]

  return (
    <div
      className={cn(
        "relative flex flex-col border-r bg-background",
        isCollapsed ? "w-16" : "w-64"
      )}
    >
      <div className="flex h-16 items-center justify-start px-4">
        {!isCollapsed && (
          <span className="text-lg font-semibold text-left">Spekule</span>
        )}
        <Button
          variant="ghost"
          size="icon"
          className="ml-auto hover:bg-gray-200"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <ChevronRight
            className={cn(
              "h-4 w-4 transition-transform"
            )}
          />
        </Button>
      </div>
      <ScrollArea className="flex-1" style={{ textAlign: 'left' }}>
        <nav className="flex flex-col gap-2 p-2">
          {navItems.map((item) => {
            if (item.link) {
              return (
                <Link key={item.name} to={item.href}>
                  <Button
                    variant="ghost"
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      textAlign: 'left',

                      '&:hover': {
                        backgroundColor: '#020817',
                        color: '#00bcd4',
                      }
                    }}
                    className={cn(
                      "w-full justify-start text-left hover:bg-gray-200 hover:text-[#020817]"
                    )}
                  >
                    <item.icon className="mr-2 h-4 w-4" />
                    {!isCollapsed && <span className="text-left">{item.name}</span>}
                  </Button>
                </Link>
              );
            } else {
              return (
                <Button
                  variant="ghost"
                  className={cn(
                    "w-full justify-start text-left hover:bg-gray-200"
                  )}
                  key={item.name}
                  onClick={item.action}
                >
                  <item.icon className="mr-2 h-4 w-4" />
                  {!isCollapsed && <span className="text-left">{item.name}</span>}
                  {item.component && item.component}
                </Button>
              );
            }
          })}
        </nav>
      </ScrollArea>
      {!isCollapsed && (
        <div className="p-4 border-t border-gray-800">
          <button onClick={() => setOpen(true)} className="flex items-center justify-between w-full">
            <span className="text-sm text-gray-400">Add Exchange</span>
            <AddIcon className="ml-2" />
          </button>
          {open && (
            <div className="popup">
              <h3 className="text-sm text-gray-400 text-right">Add Exchange</h3>
              <form onSubmit={(e) => { e.preventDefault(); handleAddExchange(); }}>
                <select
                  name="exchangeName"
                  value={exchangeData.exchangeName}
                  onChange={handleInputChange}
                >
                  <option value="">Select Exchange</option>
                  <option value="binance">Binance</option>
                  <option value="kucoin">KuCoin</option>
                  <option value="bitget">Bitget</option>
                  <option value="bybit">Bybit</option>
                </select>
                <input
                  type="text"
                  name="apiKey"
                  placeholder="API Key"
                  value={exchangeData.apiKey}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="apiSecret"
                  placeholder="API Secret"
                  value={exchangeData.apiSecret}
                  onChange={handleInputChange}
                />
                {(exchangeData.exchangeName === 'bitget' || exchangeData.exchangeName === 'kucoin') && (
                  <input
                    type="text"
                    name="passphrase"
                    placeholder="Passphrase"
                    value={exchangeData.passphrase}
                    onChange={handleInputChange}
                  />
                )}
                <button type="submit">Submit</button>
                <button type="button" onClick={() => setOpen(false)}>Cancel</button>
              </form>
            </div>
          )}
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-400">AUTOMATION</span>
            <AutomatedSettingsComponent />
          </div>
        </div>
      )}
    </div>
  );


}
