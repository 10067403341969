'use client'

import { useState } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

export function FeatureCard({ name, description, icon: Icon }) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <motion.div
      className="flex flex-col p-6 bg-gray-800 rounded-lg shadow-lg transition-shadow duration-300 ease-in-out hover:shadow-purple-500/20"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileHover={{ scale: 1.05 }}
    >
      <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-white mb-4">
        <motion.div
          className="flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full bg-purple-600"
          animate={{ rotate: isHovered ? 360 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </motion.div>
        {name}
      </dt>
      <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-400">
        <p className="flex-auto">{description}</p>
      </dd>
    </motion.div>
  )
}

FeatureCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
}
