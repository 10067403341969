'use client'

import { Button } from "../ui/Button"
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'
import { useState } from 'react';
export function Navbar() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false)

  return (
    <header className="fixed top-0 w-full z-50 border-b border-gray-800 bg-black/80 backdrop-blur-sm">
      <nav className="container mx-auto px-6 h-16 flex items-center justify-between">
        <Link href="/" className="flex items-center gap-2">
          <div className="relative w-8 h-8">
            <img
              src="/logoSpekuleTransparent.png"
              alt="Spekule"
              fill
              className="object-contain"
            />
          </div>
          <span className="font-semibold text-xl bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 bg-clip-text text-transparent">
            Spekule
          </span>
        </Link>

        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            className="text-gray-300 hover:text-white hover:bg-gray-800"
          >
            Documentation
          </Button>
          <Button
            variant="ghost"
            className="text-gray-300 hover:text-white hover:bg-gray-800"
          >
            About
          </Button>
          <Button
            className="bg-purple-600 hover:bg-purple-700 text-white relative overflow-hidden"
            onClick={() => navigate("/login")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span className="relative z-10">Sign In</span>
            <motion.div
              className="absolute inset-0 bg-pink-600"
              initial={{ x: '-100%' }}
              animate={{ x: isHovered ? '0%' : '-100%' }}
              transition={{ duration: 0.3 }}
            />
          </Button>
        </div>
      </nav>
    </header>
  )
}

