import React, { useState, useEffect } from 'react';
import { AskGptPromt } from '../services/api';
import { useUser } from '../context/UserContext';
import { fetchTradeHistory } from '../services/api';
import './SpekuleGpt.css'; // Import the CSS file
import { Button, Box } from '@mui/material';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { Send } from "lucide-react";

export function SpekuleGptComponent() {
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const { user } = useUser();
    const username = user.username;

    useEffect(() => {
      // Load historical chat data when component mounts
      const loadChatHistory = async () => {
        try {
          const history = await fetchTradeHistory(user.id, 'last week');
          setChatHistory(history.map(trade => ({ role: 'assistant', content: trade.message })));
        } catch (error) {
          console.error("Error loading chat history:", error);
        }
      };

      loadChatHistory();
    }, [user.id]);

    const handleInputChange = (e) => {
      setUserInput(e.target.value);
    };

    const handleSend = async () => {
      if (!userInput.trim()) return;

      setChatHistory([...chatHistory, { role: 'user', content: userInput }]);

      try {
        const data = await AskGptPromt(userInput, username);
        setChatHistory([...chatHistory, { role: 'user', content: userInput }, { role: 'assistant', content: data.response }]);
        setUserInput('');
      } catch (error) {
        console.error('Error communicating with the server:', error);
      }
    };
    return (
      <div className="flex flex-col items-center justify-center w-full h-screen bg-black/95">
        <ScrollArea className="flex-1 p-4 w-full overflow-y-auto">
        <div className="flex-1 flex flex-col justify-center items-center space-y-4 mb-8 h-90 overflow-y-auto">
          {/* <div className="flex flex-col justify-center items-center space-y-4 mb-8 h-full"> */}
            {chatHistory.map((message, i) => (
              <div
                key={i}
                className={`flex max-w-[80%] flex-col gap-2 rounded-lg px-4 py-2 text-sm ${
                  message.role === 'user'
                    ? 'ml-auto bg-purple-600 text-white'
                    : 'bg-zinc-800 text-zinc-50'
                }`}
              >
                {message.content}
              </div>
            ))}
            {isTyping && (
              <div className="typing-indicator"></div> // Typing indicator
            )}
            {chatHistory.length === 0 && (
              <div className="flex flex-col items-center justify-center h-[60vh] text-zinc-400">
                <h2 className="text-2xl font-bold mb-2">SpekuleGPT</h2>
                <p className="text-sm">Start a conversation by typing a message below</p>
              </div>
            )}
          </div>
        </ScrollArea>
    
        <div className="sticky bottom-0 border-t border-zinc-800 p-4 w-full bg-black/95">
          <div className="flex gap-2 max-w-3xl mx-auto w-full">
            <input
              type="text"
              value={userInput}
              onChange={handleInputChange}
              onKeyDown={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Type your message..."
              className="flex-1 bg-zinc-800 text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            <Button
              onClick={handleSend}
              className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg"
            >
              <Send className="h-4 w-4" />
              <span className="sr-only">Send message</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
