// client/src/components/portfolio/PortfolioValue.js

import {  Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import React from 'react';
import { ScrollArea } from "../ui/scroll-area"

export function PortfolioValueComponent(props) {
    const { portfolioValue } = props;
    return (
        <Card>
          <CardHeader>
            <CardTitle>Portfolio Value</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-4xl font-bold">${portfolioValue ? portfolioValue.toLocaleString() : '0'}</div>
          </CardContent>
        </Card>
      );

}

export function AggregatedAssetsComponent(props) {
    const { aggregatedAssets, message,  } = props;
return (
    <Card className="h-90 bg-transparent">
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ color: '#ffffff', paddingBottom: '8px', marginTop: '18px' }}>
          Crypto Assets
        </Typography>
        {aggregatedAssets.length > 0 ? (
          <ScrollArea height="305px"> {/* Set the desired height */}
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', padding: '8px', backgroundColor: '#020817' }}>Exchange</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', padding: '8px', backgroundColor: '#020817' }}>Asset</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', padding: '8px', backgroundColor: '#020817' }}>Quantity</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', padding: '8px', backgroundColor: '#020817' }}>Value (USD)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {aggregatedAssets.map((asset) => (
                    <TableRow key={asset.token} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#020817' } }}>
                      <TableCell align="center">
                        <img src={asset.logo} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#ffffff' }}>{asset.token}</TableCell>
                      <TableCell align="center" sx={{ color: '#ffffff' }}>{asset.amount}</TableCell>
                      <TableCell align="center" sx={{ color: '#ffffff' }}>${parseFloat(asset.value).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ScrollArea>
        ) : (
          <Typography variant="body1" sx={{ color: '#ffffff', padding: '0 16px' }}>
            {message || 'No assets found'}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

