// client/src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Customize primary color
        },
        secondary: {
            main: '#dc004e', // Customize secondary color
        },
        background: {
            default: '#f5f5f5', // Default background color
        },
    },
    typography: {
        h5: {
            fontWeight: 600, // Customize typography
        },
    },
});

export default theme;