import React, { useState, useEffect } from 'react';
import {  fetchAccountData } from '../services/api';
import { useUser } from '../context/UserContext';
import { Sidebar } from './Sidebar';
import { AssetsComponent } from './portfolio/AssetsComponent';
import { ActiveOrdersComponent } from './tradingComponents/ActiveTradesComponent';
import { TopNav } from './top-nav';
import { CryptoNews } from './tradingComponents/NewsComponent';

function Dashboard() {
    const { user } = useUser();
    const [portfolioValue, setPortfolioValue] = useState(null);
    const [aggregatedAssets, setAggregatedAssets] = useState([]);
    const [message, setMessage] = useState('');
    const [cachedAssets, setCachedAssets] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (cachedAssets) {
                setAggregatedAssets(cachedAssets);
                const totalValue = cachedAssets.reduce((sum, asset) => sum + parseFloat(asset.value), 0);
                setPortfolioValue(totalValue);
            } else {
                try {
                    const assets = await fetchAccountData(user.username);

                    if (assets.length === 0) {
                        setMessage('No exchanges connected');
                    } else {
                        setAggregatedAssets(assets);
                        setCachedAssets(assets);
                        // Calculate portfolio value
                        const totalValue = assets.reduce((sum, asset) => sum + parseFloat(asset.value), 0);
                        setPortfolioValue(totalValue);
                    }
                } catch (error) {
                    console.error('Error fetching aggregated assets:', error);
                    setMessage('Error fetching data');
                }
            }
        };

        fetchData();
    }, [user.username, cachedAssets]);

    return (
        <div className="flex h-screen bg-background">
            <Sidebar />
            <div className="flex flex-col flex-1 overflow-hidden">
                <TopNav />
                <main className="flex-1 overflow-y-auto p-4">
                    <div className="flex flex-row gap-4">
                        <div className="flex-1 h-full">
                            <CryptoNews />
                        </div>
                        <div className="flex-1 overflow-y-auto ">
                            <AssetsComponent showAggregatedAssets={true} />
                        </div>
                    </div>
                    <div className="h-screen w-full">
                        <h2 className="text-xl font-bold mb-4 mt-4">ACTIVE ORDERS</h2>
                        <ActiveOrdersComponent />
                    </div>
                </main>
            </div>
        </div>
    );
}
export default Dashboard;
