import { useState, useEffect } from 'react';
import { fetchAccountData } from '../../services/api';
import { useUser } from '../../context/UserContext';
import { AggregatedAssetsComponent, PortfolioValueComponent } from './PortfolioValueComponent';

export function AssetsComponent({ showOnlyPortfolioValue = false , showAggregatedAssets = false}) {
    const [cachedAssets, setCachedAssets] = useState(null);
    const { user } = useUser();
    const [portfolioValue, setPortfolioValue] = useState(null);
    const [aggregatedAssets, setAggregatedAssets] = useState([]);
    const [message, setMessage] = useState('');
 useEffect(() => {
        const fetchData = async () => {
            if (cachedAssets) {
                setAggregatedAssets(cachedAssets);
            } else {
                try {
                    const data = await fetchAccountData(user.username);
                    const assets = data || []; 

                    if (assets.length === 0) {
                        setMessage('No exchanges connected');
                    } else {
                        setAggregatedAssets(assets);
                        setCachedAssets(assets);
                        const totalValue = assets.reduce((sum, asset) => sum + parseFloat(asset.value), 0);
                        setPortfolioValue(totalValue);
                    }
                } catch (error) {
                    console.error('Error fetching aggregated assets:', error);
                    setMessage('Error fetching data');
                }
            }
        };

        fetchData();
    }, [user.username, cachedAssets]);

    return (
        <>
            {showOnlyPortfolioValue && !showAggregatedAssets && (
                <PortfolioValueComponent portfolioValue={portfolioValue} />
            )}
            {showAggregatedAssets && !showOnlyPortfolioValue && (
                <AggregatedAssetsComponent aggregatedAssets={aggregatedAssets} message={message} />
            )}
        </>
    );

}