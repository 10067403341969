

import React, { useState, useEffect } from 'react';
import { fetchUltraData1D, fetchUltraData15M } from '../../services/api';
import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
} from '@mui/material';
export function UltraComponent() {
    const [ultraData1D, setUltraData1D] = useState([]);
    const [ultraData15M, setUltraData15M] = useState([]);
    const [tokensCache1D, setTokensCache1D] = useState(null);
    const [tokensCache15M, setTokensCache15M] = useState(null);

    useEffect(() => {
        const fetchUltraDataRequest = async () => {
            if (tokensCache1D) {
                setUltraData1D(tokensCache1D);
                setUltraData15M(tokensCache15M);

                return;
            }

            try {
                const response1D = await fetchUltraData1D();
                const response15M = await fetchUltraData15M();
                setTokensCache1D(response1D); // Cache the data
                setUltraData1D(response1D);
                setTokensCache15M(response15M);
                setUltraData15M(response15M);


            } catch (error) {
                console.error('Error loading ultra data:', error);
            }
        };

        fetchUltraDataRequest();
    }, []);

    const sortedData1D = [...ultraData1D].sort((a, b) => b.avgScore - a.avgScore);
    const sortedData15M = [...ultraData15M].sort((a, b) => b.avgScore - a.avgScore);

    const maxAbsValue1D = Math.max(...sortedData1D.map((d) => Math.abs(d.avgScore))) || 1;
    const maxAbsValue15M = Math.max(...sortedData15M.map((d) => Math.abs(d.avgScore))) || 1;
    const renderTable = (data, maxAbsValue, title) => (
        <Box sx={{ margin: '10px', textAlign: 'center' }}>
            <Typography variant="h6" sx={{ color: '#FFFFFF', marginBottom: '10px' }}>
                {title}
            </Typography>
            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0', boxShadow: '0 0 10px 2px #FFFFFF', minHeight: '400px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Index</TableCell>
                        <TableCell align="left" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Token</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Score</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>POC</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(data) && data.length > 0 ? (
                        data.map((result, index) => {
                            let backgroundColor;
                            const avgScore = result.avgScore !== undefined ? result.avgScore : 0;
                            if (avgScore > 0) {
                                const blueIntensity = avgScore / maxAbsValue;
                                backgroundColor = `rgba(0, 123, 255, ${0.2 + blueIntensity * 0.3})`;
                            } else {
                                const redIntensity = Math.abs(avgScore) / maxAbsValue;
                                backgroundColor = `rgba(255, 69, 58, ${0.2 + redIntensity * 0.3})`;
                            }

                            return (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: backgroundColor,
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: '#333',
                                        },
                                    }}
                                >
                                    <TableCell align="center" sx={{ color: '#FFFFFF' }}>{index + 1}</TableCell>
                                    <TableCell sx={{ color: '#FFFFFF' }}>{result.token}</TableCell>
                                    <TableCell align="center" sx={{ color: '#FFFFFF' }}>{result.avgScore.toFixed(2)}</TableCell>
                                    <TableCell align="center" sx={{ color: '#FFFFFF' }}>{result.poc || 'N/A'}</TableCell>
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                minHeight: '100vh',
                backgroundColor: '#000',
                width: '100%',
                padding: '20px',
            }}
        >
            {renderTable(sortedData1D, maxAbsValue1D, '1 Day')}
            {renderTable(sortedData15M, maxAbsValue15M, '15 Minutes')}
        </Box>
    );
  
    // return (
    //     <Box
    //         sx={{
    //             display: 'flex',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             minHeight: '100vh',
    //             backgroundColor: '#000',
    //             width: '100%',
    //         }}
    //     >
    //         <Table sx={{ borderCollapse: 'separate', borderSpacing: '0', boxShadow: '0 0 10px 2px #FFFFFF' }}>
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Index</TableCell>
    //                     <TableCell align="left" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Token</TableCell>
    //                     <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Score</TableCell>
    //                     <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>POC</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {Array.isArray(sortedData1D) && sortedData1D.length > 0 ? (
    //                     sortedData1D.map((result, index) => {
    //                         let backgroundColor;
    //                         const avgScore = result.avgScore !== undefined ? result.avgScore : 0;
    //                         if (avgScore > 0) {
    //                             const blueIntensity = avgScore / maxAbsValue1D;
    //                             backgroundColor = `rgba(0, 123, 255, ${0.2 + blueIntensity * 0.3})`;
    //                         } else {
    //                             const redIntensity = Math.abs(avgScore) / maxAbsValue1D;
    //                             backgroundColor = `rgba(255, 69, 58, ${0.2 + redIntensity * 0.3})`;
    //                         }

    //                         return (
    //                             <TableRow
    //                                 key={index}
    //                                 sx={{
    //                                     backgroundColor: backgroundColor,
    //                                     color: '#FFFFFF',
    //                                     '&:hover': {
    //                                         backgroundColor: '#333',
    //                                     },
    //                                 }}
    //                             >
    //                                 <TableCell align="center" sx={{ color: '#FFFFFF' }}>{index + 1}</TableCell>
    //                                 <TableCell sx={{ color: '#FFFFFF' }}>{result.token}</TableCell>
    //                                 <TableCell align="center" sx={{ color: '#FFFFFF' }}>{result.avgScore.toFixed(2)}</TableCell>
    //                                 <TableCell align="center" sx={{ color: '#FFFFFF' }}>{result.poc || 'N/A'}</TableCell>
    //                             </TableRow>
    //                         );
    //                     })
    //                 ) : (
    //                     <TableRow>
    //                         <TableCell colSpan={4} align="center">
    //                             No data available
    //                         </TableCell>
    //                     </TableRow>
    //                 )}
    //             </TableBody>
    //         </Table>
    //     <Table sx={{ borderCollapse: 'separate', borderSpacing: '0', boxShadow: '0 0 10px 2px #FFFFFF', marginTop: '20px' }}>
    //         <TableHead>
    //             <TableRow>
    //                 <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Index</TableCell>
    //                 <TableCell align="left" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Token</TableCell>
    //                 <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>Score</TableCell>
    //                 <TableCell align="center" sx={{ fontWeight: 'bold', color: '#FFFFFF', borderBottom: '2px solid #FFFFFF' }}>POC</TableCell>
    //             </TableRow>
    //         </TableHead>
    //         <TableBody>
    //             {Array.isArray(sortedData15M) && sortedData15M.length > 0 ? (
    //                 sortedData15M.map((result, index) => {
    //                     let backgroundColor;
    //                     const avgScore = result.avgScore !== undefined ? result.avgScore : 0;
    //                     if (avgScore > 0) {
    //                         const blueIntensity = avgScore / maxAbsValue15M;
    //                         backgroundColor = `rgba(0, 123, 255, ${0.2 + blueIntensity * 0.3})`;
    //                     } else {
    //                         const redIntensity = Math.abs(avgScore) / maxAbsValue15M;
    //                         backgroundColor = `rgba(255, 69, 58, ${0.2 + redIntensity * 0.3})`;
    //                     }

    //                     return (
    //                         <TableRow
    //                             key={index}
    //                             sx={{
    //                                 backgroundColor: backgroundColor,
    //                                 color: '#FFFFFF',
    //                                 '&:hover': {
    //                                     backgroundColor: '#333',
    //                                 },
    //                             }}
    //                         >
    //                             <TableCell align="center" sx={{ color: '#FFFFFF' }}>{index + 1}</TableCell>
    //                             <TableCell sx={{ color: '#FFFFFF' }}>{result.token}</TableCell>
    //                             <TableCell align="center" sx={{ color: '#FFFFFF' }}>{result.avgScore.toFixed(2)}</TableCell>
    //                             <TableCell align="center" sx={{ color: '#FFFFFF' }}>{result.poc || 'N/A'}</TableCell>
    //                         </TableRow>
    //                     );
    //                 })
    //             ) : (
    //                 <TableRow>
    //                     <TableCell colSpan={4} align="center">
    //                         No data available
    //                     </TableCell>
    //                 </TableRow>
    //             )}
    //         </TableBody>
    //     </Table>
    //     </Box>
    // );


}