'use client'

import { useState, useEffect } from 'react'
import { ArrowRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import { AnimatedChart } from './animated-chart'
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/Button'
import { motion } from 'framer-motion'

export function HeroSection() {
  const [isHovered, setIsHovered] = useState(false)
  const [typedText, setTypedText] = useState('')
  const fullText = 'Spekule'
  const navigate = useNavigate();

  useEffect(() => {
    let i = 0
    const typingInterval = setInterval(() => {
      if (i < fullText.length) {
        setTypedText((prev) => prev + fullText.charAt(i))
        i++
      } else {
        clearInterval(typingInterval)
      }
    }, 100)

    return () => clearInterval(typingInterval)
  }, [])

  return (
    <section className="relative px-6 py-24 sm:px-12 sm:py-32 overflow-hidden">
      <div className="relative mx-auto max-w-7xl">
        <div className="flex flex-col items-center justify-center relative">
          <div className="w-48 h-48 mb-8 relative">
            <div className="absolute inset-0 bg-purple-600 rounded-full animate-pulse"></div>
            <div className="absolute inset-2 bg-black rounded-full flex items-center justify-center">
              <img src="/logoSpekuleTransparent.png" alt="Spekule Logo" className="object-contain w-full h-full" />
            </div>
          </div>
          <h1 className="text-center text-5xl font-bold tracking-tight sm:text-7xl bg-clip-text text-transparent" style={{ backgroundImage: 'linear-gradient(45deg, #00E5BE, #0000FF, #FF4B7C)' }}>
            {typedText}
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
            Experience the future of crypto trading with our advanced platform. Fast, secure, and intuitive.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
              size="lg"
              className="bg-purple-600 hover:bg-purple-700 flex items-center justify-center group relative overflow-hidden"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => navigate("/register")}
            >
              <span className="relative z-10">Get Started</span>
              <motion.div
                className="absolute inset-0 bg-pink-600"
                initial={{ x: '-100%' }}
                animate={{ x: isHovered ? '0%' : '-100%' }}
                transition={{ duration: 0.3 }}
              />
              <ArrowRight className="ml-2 h-4 w-4 relative z-10" />
            </Button>
            <Link href="#features" className="text-sm font-semibold leading-6 text-white hover:text-purple-400 transition-colors">
              Learn more <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="absolute -z-10 bottom-0 left-0 w-full h-64 opacity-20">
        <AnimatedChart />
      </div>
    </section>
  )
}

