import React from 'react';
import {GoogleSheetsComponent } from './tradingComponents/GoogleSheetsComponent';
import { Sidebar } from './Sidebar';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { Button, Box } from '@mui/material';
import { TopNav } from './top-nav';
function ScreamerPage() {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [open, setOpen] = useState(false);
    return (  
      <div className="flex h-screen bg-background">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-hidden">
        <TopNav />
        <main className="flex-1 overflow-y-auto p-4">
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <GoogleSheetsComponent />
          </div>
        </main>
      </div>
    </div>
  )
}

export default ScreamerPage;