import React from 'react';
import { IconButton, TextField, Box, Typography } from '@mui/material';
import {
    FirstPage,
    LastPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    
} from '@mui/icons-material';


function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const [pageInput, setPageInput] = React.useState(page + 1);
    const totalPages = Math.ceil(count / rowsPerPage);

    React.useEffect(() => {
        setPageInput(page + 1);
    }, [page]);

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, totalPages - 1);
    };

    const handlePageInputChange = (event) => {
        setPageInput(event.target.value);
    };

    const handlePageInputBlur = (event) => {
        let newPage = Number(event.target.value) - 1;
        if (isNaN(newPage) || newPage < 0) {
            newPage = 0;
        } else if (newPage >= totalPages) {
            newPage = totalPages - 1;
        }
        onPageChange(event, newPage);
    };

    const handlePageInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    };

    const iconButtonStyle = {
        color: '#00e676',
        '&.Mui-disabled': {
            color: '#555',
        },
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, display: 'flex', alignItems: 'center' }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                sx={iconButtonStyle}
            >
                <FirstPage />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                sx={iconButtonStyle}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <TextField
                value={pageInput}
                onChange={handlePageInputChange}
                onBlur={handlePageInputBlur}
                onKeyDown={handlePageInputKeyDown}
                variant="standard"
                size="small"
                inputProps={{
                    style: { textAlign: 'center', width: '40px' },
                    min: 1,
                    max: totalPages,
                    type: 'number',
                }}
            />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
                / {totalPages}
            </Typography>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= totalPages - 1}
                aria-label="next page"
                sx={iconButtonStyle}
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= totalPages - 1}
                aria-label="last page"
                sx={iconButtonStyle}
            >
                <LastPage />
            </IconButton>
        </Box>
    );
}

export default TablePaginationActions;