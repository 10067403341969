import { CardContent, Select, MenuItem } from '@mui/material';
import { Card, CardHeader, CardTitle } from '../ui/card';
export function AllSystemAnalyticsComponent(props) {
    const { allMetrics } = props;

    return (
        <Card >
            <CardHeader>
                <CardTitle>All System Analysis</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="mt-4 space-y-2">
                    {Object.entries(allMetrics).map(([metricName, value]) => (
                        <div key={metricName} className="flex justify-between">
                            <span className="text-sm font-medium">{metricName}</span>
                            <span className="text-sm">
                                {typeof value === 'number' ? value.toLocaleString() : value}
                            </span>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
}

export function OneSystemAnalyticalComponent(props) {
    const { systemMetrics, selectedSystem, handleSystemChange } = props;
    return (
        <Card style={{ width: '200%' }}>
            <CardHeader>
                <CardTitle>Selected System Analysis</CardTitle>
            </CardHeader>
            <CardContent>
                <Select
                    value={selectedSystem}
                    onChange={handleSystemChange}
                    displayEmpty
                    fullWidth
                    style={{ 
                        borderColor: 'white', 
                        borderWidth: '1px', 
                        borderStyle: 'solid', 
                        color: 'white',
                        '& .MuiSelect-icon': {
                            color: 'white'
                        }
                    }}
                >
                    <MenuItem value="" disabled>Select a system</MenuItem>
                    {Object.keys(systemMetrics).map((systemName) => (
                        <MenuItem key={systemName} value={systemName}>
                            {systemName}
                        </MenuItem>
                    ))}
                </Select>

                {selectedSystem && (
                    <div className="mt-4 space-y-2">
                        {Object.entries(systemMetrics[selectedSystem] || {}).map(([metricName, value]) => (
                            <div key={metricName} className="flex justify-between">
                                <span className="text-sm font-medium">{metricName}</span>
                                <span className="text-sm">
                                    {typeof value === 'number' ? value.toLocaleString() : value}
                                </span>
                            </div>
                        ))}
                    </div>
                )}

            </CardContent>
        </Card>
    );


}