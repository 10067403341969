
import { useEffect, useState } from 'react';
import { calculateEquityCurve } from './EquityCurveComponent';
import { useUser } from '../../context/UserContext';
import { mean, standardDeviation } from 'simple-statistics';
import { fetchDailyProfitLoss, fetchTradeHistory } from '../../services/api';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { ArrowUpRight, Briefcase, TrendingUp } from 'lucide-react';
export function KeyMetricsComponent() {
    const { user } = useUser();
    const username = user.username;
    const [dailyProfitLoss, setDailyProfitLoss] = useState([]);
    const [equityCurve, setEquityCurve] = useState([]);
    const [analysisData, setAnalysisData] = useState({});
    
    useEffect(() => {
        const fetchKeyMetrics = async () => {
            const dailyProfitLoss = await fetchDailyProfitLoss(username);
            setDailyProfitLoss(dailyProfitLoss || []);

            const calculatedEquityCurve = dailyProfitLoss.length > 0 ? calculateEquityCurve(dailyProfitLoss) : [];
            setEquityCurve(calculatedEquityCurve);

            const returns = Array.isArray(dailyProfitLoss) ? dailyProfitLoss.map(({ percentage }) => percentage) : [];
            const trades = await fetchTradeHistory(username); 
            const maximumDrawdown = calculateMaximumDrawdown(equityCurve.map(point => point.cumulativePnL));
            const sharpeRatio = calculateSharpeRatio(returns);
            const winLossRatio = calculateWinLossRatio(trades);
            const omegaRatio = calculateOmegaRatio(returns);
            const expectedValue = calculateExpectedValue(trades);

            setAnalysisData({ maximumDrawdown, sharpeRatio, winLossRatio, omegaRatio, expectedValue });




        };
        fetchKeyMetrics();
    }, [username]);


    const calculateMaximumDrawdown = (equityCurve) => {
        let peak = equityCurve[0];
        let maxDrawdown = 0;
        for (let i = 1; i < equityCurve.length; i++) {
            if (equityCurve[i] > peak) {
                peak = equityCurve[i];
            }
            const drawdown = (peak - equityCurve[i]) / peak;
            if (drawdown > maxDrawdown) {
                maxDrawdown = drawdown;
            }
        }
        return maxDrawdown;
    };
    const calculateSharpeRatio = (returns, riskFreeRate = 0) => {
        const avgReturn = returns.length > 0 ? mean(returns) : 0;
        const stdDev = returns.length > 0 ? standardDeviation(returns) : 0;
        const sharpeRatio = (avgReturn - riskFreeRate) / stdDev;
        return sharpeRatio;
    };
    const calculateWinLossRatio = (trades) => {
        const wins = trades.filter(trade => trade.PnL > 0).length;
        const losses = trades.filter(trade => trade.PnL <= 0).length;
        return wins / (losses || 1); // Avoid division by zero
    };
    const calculateOmegaRatio = (returns, threshold = 0) => {
        const gains = returns.filter(r => r > threshold).reduce((sum, r) => sum + (r - threshold), 0);
        const losses = returns.filter(r => r <= threshold).reduce((sum, r) => sum + (threshold - r), 0);
        return losses === 0 ? Infinity : gains / losses;
    };
    const calculateExpectedValue = (trades) => {
        if (trades.length === 0) return 0;
        const totalPnL = trades.reduce((sum, trade) => sum + trade.PnL, 0);
        return totalPnL / trades.length;
    };
    const calculatePortfolioPerformance = (initialValue, currentValue) => {
        if (initialValue === 0) return 0; // Avoid division by zero
        return ((currentValue - initialValue) / initialValue) * 100;
    };

        return (
          <Card>
            <CardHeader>
              <CardTitle>Key Metrics</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <div className="flex items-center">
                <Briefcase className="mr-2 h-4 w-4 text-muted-foreground" />
                <span className="text-sm font-medium">Sharpe Ratio</span>
                <span className="ml-auto text-sm text-green-500">{analysisData.sharpeRatio}</span>
              </div>
              <div className="flex items-center">
                <ArrowUpRight className="mr-2 h-4 w-4 text-muted-foreground" />
                <span className="text-sm font-medium">Omega Ratio</span>
                <span className="ml-auto text-sm text-green-500">{analysisData.omegaRatio}</span>
              </div>
              <div className="flex items-center">
                <TrendingUp className="mr-2 h-4 w-4 text-muted-foreground" />
                <span className="text-sm font-medium">Win Ratio</span>
                <span className="ml-auto text-sm text-green-500">{analysisData.winLossRatio}</span>
              </div>
            </CardContent>
          </Card>
        );
      

}
