'use client'

import { useState } from 'react'
import { Button } from '../ui/Button'
import { ArrowRight } from 'lucide-react'
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';
export function CTASection() {
    const [isHovered, setIsHovered] = useState(false)
    const navigate = useNavigate();

  return (
    <section className="relative isolate overflow-hidden bg-gray-900">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Ready to start trading?
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Join thousands of traders who have already discovered the power of Spekue. Sign up now and take your trading to the next level.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button
              size="lg"
              className="bg-purple-600 hover:bg-purple-700 flex items-center justify-center group relative overflow-hidden"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => navigate("/register")}
            >
              <span className="relative z-10">Sign up now</span>
              <motion.div
                className="absolute inset-0 bg-pink-600"
                initial={{ x: '-100%' }}
                animate={{ x: isHovered ? '0%' : '-100%' }}
                transition={{ duration: 0.3 }}
              />
              <ArrowRight className="ml-2 h-4 w-4 relative z-10" />
            </Button>
            <Link href="#" className="text-sm font-semibold leading-6 text-white hover:text-purple-400 transition-colors">
              Learn more <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true"
      >
        <circle cx={512} cy={512} r={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#E935C1" />
          </radialGradient>
        </defs>
      </svg>
    </section>
  )
}

