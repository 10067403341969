import Plotly from 'plotly.js-dist';
import { useEffect, useState } from "react";
import { fetchCurrentPrice, fetchOrderBookforHistogram } from "../../services/api";

export function OrderBookHistogramComponent(props) {
  const {symbol: symbolProps} = props;
  
  const [symbol, setSymbol] = useState('BTCUSDT');
    useEffect(() => {
      handleFetchData(); // Call handleFetchData on component mount to fetch data for BTC
    }, []);

    useEffect(() => {
      setSymbol(symbolProps);
    }, [symbolProps]);
  
  
    const handleFetchData = async () => {
      try {
        const data = await fetchOrderBookforHistogram(symbol);
        plotOrderBookData(data);
  
        const bids = [];
        const asks = [];
    
        data.forEach(d => {
          if (d.data && d.data.prices && d.data.volumes) {
            d.data.prices.forEach((price, index) => {
              const volume = d.data.volumes[index];
              if (volume > 0) {
                bids.push({ price, volume, exchange: d.exchange });
              } else {
                asks.push({ price, volume: Math.abs(volume), exchange: d.exchange });
              }
            });
          }
        });        
  
      } catch (error) {
        console.error('Error fetching order book data:', error);
      }
    };
  
    const plotOrderBookData = (data) => {
      const traces = data.flatMap(({ exchange, data }) => [
        {
          x: data.volumes.filter(v => !isNaN(v)),
          y: data.prices.filter(p => !isNaN(p)),
          type: 'bar',
          orientation: 'h',
          name: exchange,
          marker: {
            color: getExchangeColor(exchange)
          }
        }
      ]);
    
      const layout = {
        title: 'Order Book Data: Prices vs Volumes',
        xaxis: {
          title: 'Volume',
          color: 'white',
          automargin: true,
          autorange: true,
        },
        yaxis: {
          title: 'Price',
          color: 'white',
          automargin: true,
          autorange: true,
          fixedrange: false, // Allow zooming and panning
        },
        paper_bgcolor: '#000',
        plot_bgcolor: '#000',
        showlegend: true,
        bargap: 0.05,
        bargroupgap: 0.2,

        // annotations: [
        //   {
        //     x: 0, // Position horizontally at the start of the chart
        //     y: currentPrice, // Current price value
        //     xref: 'paper', // Anchored to the plot's x-axis
        //     yref: 'y', // Anchored to the price on the y-axis
        //     text: `Current Price: ${currentPrice}`, // Label for the marker
        //     showarrow: true, // Show an arrow pointing to the line
        //     arrowhead: 6, // Style of the arrowhead
        //     ax: -40, // Horizontal offset of the annotation label
        //     ay: 0, // Vertical offset of the annotation label
        //     font: {
        //       color: 'yellow',
        //       size: 12,
        //     },
        //     align: 'center',
        //     arrowcolor: 'yellow',
        //     bgcolor: '#222',
        //     bordercolor: 'yellow',
        //     borderwidth: 2,
        //   },
        // ],
        // shapes: [
        //   {
        //     type: 'line',
        //     xref: 'paper', // Use the full width of the chart
        //     x0: 0,
        //     x1: 1, // Extend across the entire x-axis
        //     yref: 'y',
        //     y0: currentPrice, // Start the line at the current price
        //     y1: currentPrice, // End the line at the current price
        //     line: {
        //       color: 'yellow',
        //       width: 2,
        //       dash: 'dash', // Dashed line style
        //     },
        //   },
        // ],
      
      };
    
      try {
        Plotly.newPlot('orderBookChart', traces, layout, { scrollZoom: true });
      } catch (error) {
        console.error("Error plotting chart:", error);
      }
    };

    const getExchangeColor = (exchange) => {
      const colors = {
        'binance ': 'yellow',
        'okx ': 'green',
        'kucoin ': 'purple',
        'bitget ': 'orange',
      };
      return colors[exchange];
    };

    return (
      <div id="orderBookChart" style={{ width: '100%', height: 'auto' }}></div>
    );

}