/* global TradingView */
import { useState, useEffect, useRef } from 'react';

export function TradingViewChart(props) {
  const {symbol: symbolProps} = props;
 const containerRef = useRef(null)
    const [symbol, setSymbol] = useState('BTCUSDT');

    useEffect(() => {
      setSymbol(symbolProps);
    }, [symbolProps]);
  
    useEffect(() => {
      initializeTradingViewChart(symbol);
  
    }, [symbol]);
  
    const initializeTradingViewChart = (symbol) => {
      if(containerRef.current) {
      new TradingView.widget({
        container_id: 'tradingview_chart',
        symbol: symbol,
        interval: 'D',
        theme: 'dark',
        style: '1',
        locale: 'en',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        allow_symbol_change: true,
        hideideas: true,
        container_id: containerRef.current.id,
      });
    }
    };

    return(
      <div id="tradingview_chart" ref={containerRef} className="h-full w-full" />
    );
  }