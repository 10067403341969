import React, { useState, useEffect } from 'react';
import { fetchTokensByMarketCap } from '../../services/api';

import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
    Link,
} from '@mui/material';
import TablePaginationActions from './TablePaginationActions'; // Ensure the path is correct
import './CoinGeckocomponent.css';
export function CoinGeckoComponent() {
    const [tokens, setTokens] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const tokensCache = useState(null);

    useEffect(() => {
        const loadTokensByMarketCap = async () => {
            if (tokensCache.current) {
                setTokens(tokensCache.current);
                return;
            }
            try {
                const tokensData = await fetchTokensByMarketCap(1);
                tokensCache.current = tokensData;
                setTokens(tokensData);
            } catch (error) {
                console.error('Error loading tokens:', error);
            }
        };

        loadTokensByMarketCap();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Index</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Name</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Symbol</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Current Price (USD)</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Price Change 24h</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Total Volume</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="right">Market Cap</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }} align="center">Closest Fibonacci Level</TableCell>
                        <TableCell className="custom-header-cell" style={{ fontWeight: 'bold', color: 'white' }}>Fibonacci Levels</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tokens
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((token, index) => (
                            <TableRow hover key={token.id || index} style={{ backgroundColor: '#1e1e1e' }}>
                                <TableCell align="center" style={{ color: '#00e676' }}>
                                    {page * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell>
                                    <Link
                                        href={`https://www.coingecko.com/en/coins/${token.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="hover"
                                        style={{ color: '#2196f3' }}
                                    >
                                        {token.name}
                                    </Link>
                                </TableCell>
                                <TableCell style={{ color: '#bdbdbd' }}>{token.symbol}</TableCell>
                                <TableCell align="right" style={{ color: '#ffeb3b' }}>
                                    ${token.current_price.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        style={{
                                            color: token.price_change_24h >= 0 ? '#4caf50' : '#f44336',
                                        }}
                                    >
                                        {token.price_change_24h
                                            ? `${token.price_change_24h.toFixed(2)}%`
                                            : 'N/A'}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" style={{ color: '#bdbdbd' }}>
                                    {token.total_volume.toLocaleString()}
                                </TableCell>
                                <TableCell align="right" style={{ color: '#bdbdbd' }}>
                                    ${token.market_cap.toLocaleString()}
                                </TableCell>
                                <TableCell align="center" style={{ color: '#bdbdbd' }}>
                                    {token.closestFibLevel}
                                </TableCell>
                                <TableCell>
                                    {token.fibLevels.map((level, i) => (
                                        <Typography variant="body2" key={i} style={{ color: '#bdbdbd' }}>
                                            {i + 1}: {level.toFixed(8)}
                                        </Typography>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={tokens.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{
                    position: 'sticky',
                    bottom: '0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: 0,
                    backgroundColor: 'black',
                    color: '#ffffff',
                    '& .MuiSelect-icon': {
                        color: '#00e676',
                    },
                }}
            />
        </div>
    );


}