import React, { useState, useEffect } from 'react';
import { AskGptPromt } from '../services/api';
import { useUser } from '../context/UserContext';
import { fetchTradeHistory } from '../services/api';
import './SpekuleGpt.css'; // Import the CSS file
import { Sidebar } from './Sidebar';
import { TopNav } from './top-nav';
import { SpekuleGptComponent }from './SpekuleGptComponent';
function SpekuleGpt() {
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const { user } = useUser();
    const username = user.username;

    useEffect(() => {
      // Load historical chat data when component mounts
      const loadChatHistory = async () => {
        try {
          const history = await fetchTradeHistory(user.id, 'last week');
          setChatHistory(history.map(trade => ({ role: 'assistant', content: trade.message })));
        } catch (error) {
          console.error("Error loading chat history:", error);
        }
      };

      loadChatHistory();
    }, [user.id]);

    const handleSend = async () => {
      if (!userInput.trim()) return;

      setChatHistory(prevChatHistory => [...prevChatHistory, { role: 'user', content: userInput }]);

      try {
        const data = await AskGptPromt(userInput, username);
        setChatHistory(prevChatHistory => [...prevChatHistory, { role: 'user', content: userInput }, { role: 'assistant', content: data.response }]);
        setUserInput('');
      } catch (error) {
        console.error('Error communicating with the server:', error);
      }
    };

    return (
      <div className="flex h-screen bg-background">
        <Sidebar />
        <div className="flex flex-col flex-1 overflow-hidden">
          <TopNav />
          <main className="flex-1 flex justify-center items-center overflow-hidden">
            <div className="flex justify-center items-center w-full">
              <SpekuleGptComponent />
            </div>
          </main>
        </div>
      </div>
    );
  }

export default SpekuleGpt;