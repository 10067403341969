import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import {
    Badge,
    IconButton,
    Popover,
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
    Divider,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchNotifications, deleteNotification } from '../../services/api'; // Adjust the path as necessary
import CloseIcon from '@mui/icons-material/Close';
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export function NotificationPanel() {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const loadNotifications = async () => {
            try {
                const data = await fetchNotifications(user.username);
                const flippedData = data.reverse();
                setNotifications(flippedData);
            } catch (error) {
                console.error('Error loading notifications:', error);
            }
        };

        loadNotifications();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteNotification(id);
            setNotifications((prev) => prev.filter((notification) => notification._id !== id));
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick} color="inherit">
                <StyledBadge badgeContent={notifications.length} color="error">
                    <Typography variant="body1"> 🔔 </Typography>
                </StyledBadge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ width: 360, maxHeight: 400, overflow: 'auto', backgroundColor: '#020817' }}>
                    <Typography sx={{ p: 2 }} variant="h6" component="div">
                        Notifications
                    </Typography>
                    <Divider />
                    <List>
                        {notifications.map((notification) => (
                            <React.Fragment key={notification._id}>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{ color: 'white' }} // Set message text color to black
                                            >
                                                {notification.message}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="white" // Set timestamp text color to black
                                                >
                                                    {new Date(notification.timestamp).toLocaleString()} {/* Correctly format the timestamp */}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(notification._id)}>
                                        <CloseIcon sx={{ color: 'red' }} />
                                    </IconButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Popover>
        </>
    );
}