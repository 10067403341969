import React, { useEffect, useRef } from 'react';

export function AnimatedChart(props) {
  const pathRef = useRef(null);

  useEffect(() => {
    if (pathRef.current) {
      const length = pathRef.current.getTotalLength();
      pathRef.current.style.strokeDasharray = `${length} ${length}`;
      pathRef.current.style.strokeDashoffset = `${length}`;
      pathRef.current.style.animation = 'dash 5s linear infinite';
    }
  }, []);

  return (
    <svg viewBox="0 0 1000 200" {...props}>
      <defs>
        <linearGradient id="chartGradient" x1="0" y1="0" x2="1000" y2="0">
          <stop offset="0%" stopColor="#7775D6" />
          <stop offset="100%" stopColor="#E935C1" />
        </linearGradient>
      </defs>
      <path
        ref={pathRef}
        d="M0,100 Q250,180 500,100 T1000,100"
        fill="none"
        stroke="url(#chartGradient)"
        strokeWidth="4"
      />
    </svg>
  );
}
