import { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { getActiveTrades } from '../../services/api';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from "../ui/table-header"
import { Card, CardContent } from "../ui/card"


export function ActiveOrdersComponent() {
    const { user } = useUser();
    const [activeTrades, setActiveTrades] = useState([]);
    const tokensCache = useState(null); // Define tokensCache
  
  
    useEffect(() => {
      const fetchActiveTrades = async () => {
        if (tokensCache.current) {
          setActiveTrades(tokensCache.current);
          return;
        }
        try {
          const trades = await getActiveTrades(user.username);
          tokensCache.current = trades; // Cache the data
          setActiveTrades(trades);
        } catch (error) {
          console.error('Error fetching active trades:', error);
        }
      };
  
      fetchActiveTrades();
    }, [user.username]);
  




    return (
      <Card className="bg-[#0a0b0f] text-white h-96 w-full overflow-hidden ">
        <CardContent className="h-full w-full overflow-y-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Trade ID</TableHead>
                <TableHead>Symbol</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Side</TableHead>
                <TableHead>Entry Price</TableHead>
                <TableHead>Exchange</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {activeTrades.map((order) => (
                <TableRow key={order.tradeId}>
                  <TableCell className="font-medium">{order.tradeId}</TableCell>
                  <TableCell>{order.symbol}</TableCell>
                  <TableCell>{order.amount}</TableCell>
                  <TableCell className={order.side === "buy" ? "text-green-500" : "text-red-500"}>
                    {order.side}
                  </TableCell>
                  <TableCell>{order.entryPrice}</TableCell>
                  <TableCell>{order.exchange}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
}

