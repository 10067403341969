import { AnimatedChart } from './animated-chart';

import { ArrowRight,BarChart2, Zap, Lock } from 'lucide-react';
import { HeroSection } from './hero';
import { FeaturesSection } from './featureSection';
import { CTASection } from './CTAsection';
import { Navbar } from './headers';
export function LandingPage() {
  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
    <main className="min-h-screen bg-black text-white overflow-hidden">
      <HeroSection />
      <FeaturesSection />
      <CTASection />
    </main>
    </div>
  )
} 
  

  