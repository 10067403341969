import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sendVerificationCode, verifyCode, register } from '../../services/api';

export function EmailVerification() {
    const [verificationCode, setVerificationCode] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { email, username, password } = location.state || {};

    const handleVerification = async (e) => {
        e.preventDefault();
        const data = await verifyCode(email, verificationCode);
        if (data.success) {
            const registerData = await register(username, password, email);
            if (registerData.success) {
                localStorage.setItem('sessionToken', registerData.sessionToken);
                navigate('/login');
            } else {
                alert(registerData.message || 'Registration failed');
            }
        } else {
            alert(data.message || 'Verification failed');
        }
    };

    return (
        <div className="verification-form">
            <h2>Verify Your Email</h2>
            <form onSubmit={handleVerification}>
                <input
                    type="text"
                    placeholder="Enter verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                />
                <button type="submit">Verify and Register</button>
            </form>
        </div>
    );
}