// client/src/components/ui/scroll-area.js
import React from 'react';

export const ScrollArea = ({ children, height = '300px' }) => {
  return (
    <div
      style={{
        overflowY: 'auto',
        maxHeight: height,
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        backgroundColor: 'transparent',
      }}
    >
      {children}
    </div>
  );
};