import { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchDailyProfitLoss } from '../../services/api';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';

export function EquityCurveComponent() {
    const { user } = useUser();
    const username = user.username;
    const [dailyProfitLoss, setDailyProfitLoss] = useState([]);
    const [equityCurve, setEquityCurve] = useState([]);
    useEffect(() => {
        const fetchProfitLoss = async () => {
            const dailyProfitLoss = await fetchDailyProfitLoss(username);
            setDailyProfitLoss(dailyProfitLoss || []);
            const equityCurve = await calculateEquityCurve(dailyProfitLoss);
            setEquityCurve(equityCurve || []);
      
        };
        fetchProfitLoss();
    }, [username]);

    return (
        <Card className="col-span-3">
        <CardHeader>
          <CardTitle>Equity Curve</CardTitle>
        </CardHeader>
        <CardContent>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={equityCurve}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="cumulativePnL"
                stroke="#8884d8"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    );
}




export async function calculateEquityCurve(dailyProfitLoss) {
    try {

        const equityCurve = [];
            let cumulativePnL = 0;
            // Calculate the cumulative PnL for each entry
            for (const entry of dailyProfitLoss) {
                cumulativePnL += entry.totalPnL; // Assuming 'percentage' is the daily PnL
                equityCurve.push({
                    date: entry.date,
                    cumulativePnL: cumulativePnL
                });
            }
    
            return equityCurve;
        } catch (error) {
            console.error('Error calculating equity curve:', error);
            throw error;
        }
    }


