// client/src/components/auth/LoginForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/api';
import { useUser } from '../../context/UserContext';
import './LoginRegisterForm.css';
import { Card, CardHeader, CardContent, CardFooter } from '../ui/card';
import Input from '@mui/material/Input';
import { Label } from '../ui/label';
import { Button } from '../ui/Button';
import { User, Lock, Eye, EyeOff } from 'lucide-react';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

export function LoginFormComponent() {
  const { setUser } = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await login(username, password);
    if (data.success) {
      setUser({ username });
      localStorage.setItem('sessionToken', data.sessionToken);
      navigate('/trading/dashboard'); // Redirect to dashboard page after successful login
    } else {
      alert(data.message || 'Login failed');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <Card className="w-full max-w-md bg-black/50 backdrop-blur-md border-gray-800">
      <CardHeader className="space-y-1 flex flex-col items-center">
        <div className="relative w-32 h-32 mb-4">
          <img
            src="/logoSpekuleTransparent.png"
            alt="Crypto Trading Terminal Logo"
            fill
            className="object-contain w-full h-full"
          />
        </div>
        <p className="text-gray-400">Enter your credentials to access your account</p>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="space-y-4">
          <div>
            <Label htmlFor="username" className="text-gray-200">Username</Label>
            <Input
              id="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="bg-transparent text-white border-none w-full"
              startAdornment={
                <InputAdornment position="start">
                  <User className="text-white" size={18} />
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />
          </div>
          <div>
            <Label htmlFor="password" className="text-gray-200">Password</Label>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-transparent text-white w-full"
              startAdornment={
                <InputAdornment position="start">
                  <Lock className="text-white" size={18} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <EyeOff className="text-gray-400" size={18} />
                    ) : (
                      <Eye className="text-gray-400" size={18} />
                    )}
                  </Button>
                </InputAdornment>
              }
              sx={{
                color: 'white',
              }}
            />

          </div>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white">
            Sign In
          </Button>
          <div className="text-sm text-gray-400 text-center">
            Dont have an account?{' '}
            <a href="/register" className="text-blue-400 hover:underline">
              Sign up
            </a>
          </div>
        </CardFooter>
      </form>
    </Card>
  );
}

// export default LoginForm;
