import { LoginFormComponent } from './auth/LoginFormComponent';
import './auth/LoginForm.css';

export default function LoginPage() {
  return (
    <main className="flex min-h-screen items-center justify-center bg-gradient-to-br from-gray-900 to-black p-4">
      <LoginFormComponent />
    </main>
  )
}

