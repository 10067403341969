// client/src/components/ui/button.js
import React from 'react';

export const Button = ({ children, ...props }) => (
  <button
    style={{
      padding: '10px 20px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#6200ea',
      color: '#fff',
      cursor: 'pointer',
      fontWeight: 'bold',
    }}
    {...props}
  >
    {children}
  </button>
);