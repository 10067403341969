
import React from 'react';
import './PortfolioPage.css';
import { AssetsComponent } from './portfolio/AssetsComponent';
import { Sidebar } from './Sidebar';
import { SystemAnalyticsComponent } from './portfolio/SystemAnalyticsComponent';
import { DailyProfitLossComponent } from './portfolio/DailyProfitLossComponent';
import { EquityCurveComponent } from './portfolio/EquityCurveComponent';
import { KeyMetricsComponent } from './portfolio/KeyMetricsComponent';
import { TopNav }from './top-nav';


function PortfolioPage() {
  return (
    <div className="flex h-screen bg-background">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-hidden">
        <TopNav />
        <main className="flex-1 overflow-y-auto p-4">
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            <KeyMetricsComponent />
            <DailyProfitLossComponent />
            <AssetsComponent showOnlyPortfolioValue={true} />
            <EquityCurveComponent />
            <SystemAnalyticsComponent />
          </div>
        </main>
      </div>
    </div>
  )



}

export default PortfolioPage;